import React from 'react';
import './parceirias.scss';
import Hammer from 'react-hammerjs';

import img1 from './empresa1.jpg'
import img2 from './empresa2.png'
import img3 from './empresa3.jpg'





const imagesSrc = [img3,img2, img1]
const links = [ 'http://www.grupoamas.com.br/','https://www.cartaodetodos.com.br/#/','http://funerariacintra.com.br/']

var images = []

class Parceirias extends React.Component {
  isMobile
  aux
  constructor() {
    super()
    this.guarda = 1
    this.state = { posicao: { transform: `translateX(-${this.guarda}%)` } }
  }
  componentDidMount() {


    this.isMobile = this.checkForMobile()

    if (this.isMobile === 100)
      this.aux = 1
    else
      this.aux = -1

  }

  generateGridImage() {
    let cards = []
    //   imagesSrc.forEach(img => {
    //     links.forEach(link=> {      
    //       cards.push(

    //         <div className="imagem-ind">
    //           <a href={link} target="_blank" rel="noopener noreferrer">
    //             <img className="parceiros-logo" alt="description" src={img} style={{ width: '120px', height: 'auto' }} ></img>
    //          </a>
    //       </div>
    //     )
    //   })
    // })
    for (let i = 0; i < imagesSrc.length; i++) {
      cards.push(
        <div className="imagem-ind" key = {i}>
          <a href={links[i]} target="_blank" rel="noopener noreferrer">
            <img className="parceiros-logo" alt="description" src={imagesSrc[i]} style={{ width: '120px', height: 'auto' }} ></img>
          </a>
        </div>
      )
    }
    return cards
  }
  checkForMobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 100;
    }
    else {
      return 25;
    }
  }

  alteraPosicaoLeft() {
    if (this.guarda < 1)
      this.guarda = this.guarda + this.isMobile
    this.setState({ posicao: { transform: `translateX(${this.guarda}%)` } })
  }

  alteraPosicaoRight() {
    if (this.guarda > -(this.isMobile * this.aux)) {
      this.guarda = this.guarda - this.isMobile
    }
    this.setState({ posicao: { transform: `translateX(${this.guarda}%)` } })
  }





  render() {
    return (
      <div className="parceiria-boxed" id="convênios">
        <h1 className="parceiros-h">Convênios</h1>
        <div className="parceiros-div-principal">

        
          <i className="material-icons seta-parc" style={{ fontSize: '200%' }} onClick={this.alteraPosicaoLeft.bind(this)}>keyboard_arrow_left</i>
          <Hammer onSwipeRight={this.alteraPosicaoLeft.bind(this)} onSwipeLeft={this.alteraPosicaoRight.bind(this)} >

            <div className="parceiros-boxed" >
              <div id="parceiros" style={this.state.posicao}>


                {this.generateGridImage()}

                {/* <div className="button-parc">
                  <button type="button" className="botao-parc">Veja mais</button>
                </div> */}

              </div>
            </div>
          </Hammer>
          <i className="material-icons seta-parc" style={{ fontSize: '200%' }} onClick={this.alteraPosicaoRight.bind(this)}>keyboard_arrow_right</i>
        </div>
      </div>

    )
  }



}


export default Parceirias