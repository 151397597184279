import React from 'react';
import './card-image.css';

const CardImage = (props) => (
    <a href ={props.link} className="cardimage-card" target="_blank" rel="noopener noreferrer">
   
            <div style={{backgroundImage:'linear-gradient('+ props.urlOverlay + '),' + 'url(' + props.img + ')'}} className="cardimage-image" >
                
                {/* teste */}
                {
                    props.flag ?
                    <p className="cardimage-logo-p"> {props.escrito}</p> :
                    <img src={null} style={{height: '65%',width:'auto'}}></img> 

                }
            </div>
            <div className="cardimage-cardbody">
                <h4 className="cardimage-h">{props.titulo}</h4>
                <p className="cardimage-p" >{props.descricao}</p>
            </div>
  
    </a>
)

export default CardImage
