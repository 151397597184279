import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import './landingpage.scss';



const Landingpage = ({ image }) => {
  // console.log('a', image, 'b')
  return (
    <div className="capa" id="home">
      <Img className="bg-image" fluid={image.fluid}></Img>
      <div className="capa-content">
        <div className="titulo-capa-container">
          <h2 className="titulo-capa">Imagine. Nós realizamos.</h2>
        </div>
      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
    {
      image: imageSharp(original: {src: {regex: "/.*Landinpage.*/"}}) {
        id
        fluid(maxWidth: 1900) {
          base64
          tracedSVG
          src
          srcSet
          sizes
          originalName
          presentationWidth
          presentationHeight
          originalImg
        }
      }
    }
    `}
    render={data =>
      <Landingpage
        image={data.image}
        {...props}
      ></Landingpage>
    }
  />
)
