import React from 'react';
import './galeria.scss';
import ImageGallery from 'react-image-gallery';
import img1 from './galeria1.jpg';
import img2 from './galeria2.png';
import img3 from './galeria3.png';
import img4 from './galeria5.png';
import img5 from './galeria6.png';
import img6 from './galeria4.jpg';


const imagesSrc = [img1,img2, img3,img4,img5,img6]

var images = []
export default class Galeria extends React.Component {

    constructor(props) {
        super(props)
        this.state = { gallery: { display: "flex" } }

    }

    generateGridImage() {
        let cards = []
        imagesSrc.forEach(img => {
            cards.push(
                <div className="image-grid">
                    <img src={img} className="image-for-image-grid" onClick={this.makeGalleryVisible.bind(this)}></img>
                </div>
            )
        })
        return cards
    }
    makeGalleryVisible() {
        this.setState({ gallery: { display: "flex" } })

    }

    componentDidMount() {

        imagesSrc.forEach(img => {
            images.push(
                {
                    original: img,
                    thumbnail: img,

                }
            )
        })
    }

    render() {
        return (
            <div className="galeria-container" >
                <h1 className="galeria-h">Galeria</h1>
                
                

                <div className="filter-holder-gallery" style={{ display: this.state.gallery.display }} >

                    <div className="gallery-container">
                        <ImageGallery items={images} showFullscreenButton={false} autoPlay = {true}/>
                    </div>
                </div>
    <div id="serviços"></div>
            </div>
        )
    }
}
