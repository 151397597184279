import React from 'react';
import './card-especialidades.scss';

const CardEspecialidades =(props) =>(
            <div className="card-especialidades-boxed" >
                <img className="card-especialidades-imagem" src={props.img}  ></img>
                <div className="card-especialidades-info">
                    <h2 className="card-especialidades-titulo">{props.titulo}</h2>
                    <p className="card-especialidades-texto">{props.txt}</p>
                </div>
            </div>

)
  
export default CardEspecialidades

   

   
       
        


