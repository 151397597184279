import React from 'react'
import "./oquefazemos.css"
import CardText from '../card-text/card-text';

export default class OqueFazemos extends React.Component {
    cardsContent = [
        { title: "DESENVOLVIMENTO DE SOFTWARE", description: "Nós desenvolvemos software com o que há de mais moderno, traduzindo as necessidades do mundo real para o código.", color: '#00306F', colortext: 'white'  },
        { title: "DESENVOLVIMENTO DE HARDWARE", description: "Nós projetamos e prototipamos hardwares que conectam o mundo físico ao mundo digital.", color: '#555555', colortext: 'white' },
        { title: "ASSESSORIA DE MARKETING", description: "Ajudamos a reduzir o custo de lançamento de novos produtos com apoio da nossa equipe interna de marketing.",color:'#eeeeee'},
        { title: "ASSESSORIA EM NEGÓCIOS", description: "Utilizamos nossa experiência para ajudar a evitar obstáculos e aumentar a eficiência na superação de desafios.", color: '#555555', colortext: 'white' },
        { title: "ASSESSORIA JURÍDICA", description: "Facilitamos o processo burocrático e jurídico de desenvolvimento e licenciamento na criação de novos negócios.",color:'#eeeeee' },
        { title: "REDE DE CONTATOS", description: "Ajudamos a conectar projetos promissores à contatos estratégicos que viabilizam o desenvolvimento de tecnologias e a inserção no mercado.", color: '#00306F', colortext: 'white'  },

    ]
    createCards = () => {
        let cards = []
        this.cardsContent.forEach(cardContent => {
            cards.push(
                <CardText
                    key={cardContent.title}
                    color={cardContent.color}
                    colortext={cardContent.colortext}
                    titulo={cardContent.title}
                    descricao={cardContent.description}
                >
                </CardText>
            )
        })
        return cards
    }
    render() {
        return (
            <div>
                <div className="oquefazemos-card-holder">
                    {this.createCards()}
                </div>
            </div>
        )
    }
}


