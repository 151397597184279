import React from 'react'
import CardImage from '../card-image/card-image';
import "./portifolio.css"

import ImageMKT from '../../assets/MKT.png'
import ImageSecureForce from '../../assets/SecureForce.png'
import ImageVisio from '../../assets/VISIO.png'
import ImageTotti from '../../assets/totti.png'
import ImageMon from '../../assets/Mon.png'


import logoSF from '../../assets/SFLabs.png'

export default class Portifolio extends React.Component {
    cardsContent = [
        { title: "Fly Digital", description: "Marketing digital com atuação desde o desenvolvimento de sites até gerencia e monitoramento do impacto digital", url: ImageMKT,urlOverlay:'rgba(146, 198, 78,0.6),rgba(146, 198, 78,0.5)',logo: logoSF,escrito:"FLYD" ,flag: true,link:"https://www.flydigital.com.br"},
        { title: "Visio.ai", description: "Gravação na Nuvem e Video Analytics para auditoria automatizada de pontos de venda", url: ImageVisio,urlOverlay:'rgba(26,26,26,0.8),rgba(26,26,26,0.7)',logo: logoSF,escrito:"VISIO",flag: true, link:"https://visio.ai/" },
        { title: "Horus", description: "Sistema de monitoramento e gestão de segurança de barragens", url: ImageTotti,urlOverlay:'rgba(26,26,26,0.8),rgba(26,26,26,0.7)',logo: logoSF,escrito:"HORUS",flag: true },
        { title: "SISTEMA OPS", description: "Sistema de monitoramento e gestão de recursos hídricos", url: ImageMon,urlOverlay:'rgba(26,26,26,0.5),rgba(26,26,26,0.4)',escrito:"OPS",flag: true, link:"https://sistemaops.com.br/" },
        { title: "Secure Force", description: "Desenvolvimento, produção e prestação de consultoria na área de segurança cibernética", url: ImageSecureForce,urlOverlay:'rgba(0,50,108, 0.7),rgba(0,50,108, 0.6)',escrito:"SF",logo:logoSF,flag: true,link:"https://secureforce.com.br" },

    ]
    createCards = () => {
        let cards = []
        this.cardsContent.forEach(cardContent => {
            cards.push(
                <CardImage
                    key={cardContent.title}
                    titulo={cardContent.title}
                    descricao={cardContent.description}
                    img={cardContent.url}
                    logo={cardContent.logo}
                    escrito={cardContent.escrito}
                    flag={cardContent.flag}
                    urlOverlay={cardContent.urlOverlay}
                    link = {cardContent.link}
                >
                </CardImage>
            )
        })
        return cards
    }
    render() {
        return (
            <div className="portifolio-container" id="produtos" >
                <div>
                    <h1 className="portifolio-h">Portfólio</h1>
                </div>
                <div className="portifolio card-holder">
                    {this.createCards()}
                    <div className="portifolio-mobile"><p> &emsp; </p></div>
                </div>
            </div>
        )
    }
}