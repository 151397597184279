import React from 'react';
import './card-text.scss';

const CardText = (props) => (
    <div className="cardtext-card" style={{backgroundColor:props.color}}>
        <div className="cardtext-cardbody" style={{color: props.colortext}}>
            <h4 className="cardtext-p">{props.titulo}</h4>
            <p>{props.descricao}</p>
        </div>
    </div>
)

export default CardText
