import React,{ Component } from "react";
import Layout from "./layout";
import Slides from '../components/galeria/galeria';
import Landingpage from '../components/landingpage/landingpage';
import QuemSomos from '../components/quemsomos/quemsomos';
import Frase from '../components/frase/frase';
import ComplementoBar from '../components/complementoBar/complementoBar';
import SetaTopo from '../components/setatopo/setatopo';
import Contato from '../components/contato1/contato1';
import Servicos from '../components/especialidades/especialidades';
import Parceirias from '../components/parceirias/parceirias';
import Historia from '../components/historia/historia';
import CallToAction from '../components/callToAction/callToAction';
import OqueFazemos from '../components/oquefazemos/oquefazemos'
import Portifolio from '../components/portifolio/portifolio'

export default class IndexLayout extends Component {
    render(){
        return (
            <Layout>
            <Landingpage frase="Com um time de profissionais capacitados e com uma excelente estrutura, a Lat&Mia está preparada para dar ao seu pet o melhor atendimento, desde a consulta, até a emergência."></Landingpage>
            <Frase id="sobre"  h="Nós acreditamos em resolver problemas com tecnologia" p="Em alguns casos nós idealizamos, desenvolvemos e vendemos produtos. Em alguns casos projetos nascem com parceiros que enxergam novas oportunidades e dividem o trabalho conosco. Em alguns casos ajudamos clientes a resolverem problemas. Em todos eles nós desenvolvemos tecnologia de ponta." sizep="1em" ></Frase>
            <OqueFazemos></OqueFazemos>
            <Portifolio></Portifolio>
            <Contato telLink="5511957748642" tel="(11) 95774-8642" email="contato@sflabs.com.br" address="Rua Alfredo Lopes 1717, Conjunto E8"
             city = "São Carlos - SP "></Contato> 
            </Layout>
        )
    }
}