import React from 'react';
import './frase.scss'

const Frase = (props) => (
  <div className="frase-element" id="sobre" >
    <div className="frase-container" >
      <h2 className="frase-h" >
          {props.h}
      </h2>
      <p className="frase-p" style={{fontSize: '1.1em'}}>
        {props.p}
        </p>
    </div>
  </div>
)

export default Frase
