import React from 'react'
import { graphql } from 'gatsby'

import Header from '../components/header/header'
import IndexLayout from '../layout/index.layout'
import '../styles/index.scss'

export default class IndexPage extends React.Component {

  render() {
    let dados = this.props.data.markdownRemark.frontmatter
    return (
      <div>
        <Header headerData={dados.header[0]}></Header>
        <IndexLayout></IndexLayout>
      </div>
    )
  }
}

export const query = graphql`
{
  markdownRemark(frontmatter: {template: {eq: "default"}}) {
    frontmatter {
      title
      path
      header {
        pageTitle
        keyWords
        descricao
        gtmID
        gaID
      } 
    }
  }
}
`