import React from 'react';
import './especialidades.scss';
import Hammer from 'react-hammerjs';
import CardEspecialidades from '../card-especialidades/card-especialidades';

import Hospedagem from './img5.jpg'
import Vacinas from './img2.jpg'
import Cirurgia from './img3.jpg'
import Consulta from './img8.jpeg'
import Internação from './img1.jpg'
import Exames from './img6.jpeg'
import Rx from './img7.jpeg'
import Banho from './img10.jpg'
import Ozonioterapia from './img4.jpeg'
 
class Especialidade extends React.Component {
  isMobile
  aux
  constructor() {
    super()
    this.guarda = 1
    this.state = { posicao: { transform: `translateX(-${this.guarda}%)` } }
  }
  componentDidMount() {


    this.isMobile = this.checkForMobile()

    if (this.isMobile === 100)
      this.aux = 7
    else
      this.aux = 4

  }
  cardsContent = [
    { title: "Consulta ", txt: "Consultas de rotina para garantir que seu pet esteja sempre saudável.", img: Consulta },
    { title: "Vacinas Importadas", txt: "Aplicamos as vacinas necessárias para manter a saúde do seu pet sempre em dia.", img: Vacinas },
    { title: "Hospedagem", txt: "Contamos com uma estrutura e profissionais dedicados para garantir a segurança e conforto do seu pet e tranquilidade na sua viagem.", img: Hospedagem },
    { title: "Cirurgia", txt: "Possuímos os mais avançados equipamentos e técnicas para atender o seu pet.", img: Cirurgia },
    { title: "Banho e tosa", txt: "Cuidamos do seu pet para que ele possa sempre estar lindo e cheiroso.", img: Banho },
    { title: "Internação", txt: "Contamos com uma estrutura dedicada somente à internação do seu pet.", img: Internação },
    { title: "Exames Laboratoriais", txt: "Realizamos análises laboratoriais para promover diagnósticos mais precisos e seguros.", img: Exames },
    { title: "Imagem", txt: "Realizamos exames ultrassonográficos para diagnosticar corretamente e rapidamente o seu pet.", img: Rx },
    { title: "Ozonioterapia", txt: "Utilizamos a inovadora técnica de ozonioterapia para tratar diversas patologias e melhorar o bem estar do seu pet.", img: Ozonioterapia },

  ]
createCards = () => {
    let cards = []
    this.cardsContent.forEach(cardContent => {
        cards.push(
            <CardEspecialidades
                titulo={cardContent.title}
                img={cardContent.img}
                txt={cardContent.txt}
                key={cardContent.title}
            >
            </CardEspecialidades>
        )
    })
    return cards
}
  
  checkForMobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return 100;
    }
    else {
      return 25;
    }
  }

  alteraPosicaoLeft() {
    if (this.guarda < 1)
      this.guarda = this.guarda + this.isMobile
    this.setState({ posicao: { transform: `translateX(${this.guarda}%)` } })
  }

  alteraPosicaoRight() {
    if (this.guarda > -(this.isMobile * this.aux)) {
      this.guarda = this.guarda - this.isMobile
    }
    this.setState({ posicao: { transform: `translateX(${this.guarda}%)` } })
  }





  render() {
    return (
      <div className="especialidades-boxed" id="serviços">
        <h1 className="especialidade-h">Serviços</h1>
        <div className="especialidade-div-principal">

        
          <i className="material-icons seta-espec" style={{ fontSize: '300%' }} onClick={this.alteraPosicaoLeft.bind(this)}>keyboard_arrow_left</i>
          <Hammer onSwipeRight={this.alteraPosicaoLeft.bind(this)} onSwipeLeft={this.alteraPosicaoRight.bind(this)} >

            <div className="especialidade-boxed" >
              <div id="especialidade" style={this.state.posicao}>

              {this.createCards()}


                {/* <div className="button-parc">
                  <button type="button" className="botao-parc">Veja mais</button>
                </div> */}

              </div>
            </div>
          </Hammer>
          <i className="material-icons seta-espec" style={{ fontSize: '300%' }} onClick={this.alteraPosicaoRight.bind(this)}>keyboard_arrow_right</i>
        </div>
      </div>

    )
  }



}


export default Especialidade