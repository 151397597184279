import React from 'react';
import './callToAction.scss'

import predio from './background.jpg'




export default class CallToAction extends React.Component {
    flag = 0;
    constructor(props) {
        super(props);

        this.state = { whatsappLink: `https://web.whatsapp.com//send?phone=${this.props.telLink}` }

    }

    componentDidMount() {
        let isMobile = this.checkForMobile()
        if (!isMobile) {
            this.setState({ whatsappLink: `https://web.whatsapp.com//send?phone=${this.props.telLink}` })
        } else {
            this.setState({ whatsappLink: `https://wa.me/${this.props.telLink}` })
        }
    }

    checkForMobile() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        }
        else {
            return false;
        }
    }



    render() {
        return (
            <div className="call">

                <div className="call-to-action-boxed">

                    <div className="call-to-action-img">
                        <img className="call-imagem" src={predio}  ></img>
                    </div>

                    <div className="call-to-action-txt">
                        <p className="call-titulo">Conheça nossa página no Facebook </p>
                        <p className="call-txt">E fique por dentro de todas as novidades</p>
                        {/* <a className="botao-call" href={this.state.whatsappLink} target="_blank" rel="noopener">
                        <div className="botao-call-wpp">
                            <i className="fab fa-whatsapp call-contactIcone"></i>
                            <p className="p-botao-call-wpp"> Fale Conosco</p>
                        </div>
                    </a> */}
                        <a className="a-facebook" href="https://www.facebook.com/latemiaveterinariabarretos/" target="_blank" rel="noopener">
                            <i className="fab fa-facebook-square "></i>
                        </a>
                    </div>

                </div>
            </div>
        )
    }
}

